<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import H from '$lib/components/H.svelte';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import TextField from '$lib/components/TextField.svelte';

  import { getApiPaths } from '$lib/stores/getUserStore';
  import { isEmail } from 'shared/definitions/ioTsTypes/emailCodec';

  import { API } from 'shared/src/utils/api/api';
  import { createEventDispatcher } from 'svelte';

  let clazz = '';
  export { clazz as class };

  const api = new API(getApiPaths());

  let email = '';
  let errorMessage = '';
  let state: 'inputting' | 'requesting' | 'errored' | 'done' = 'inputting';

  export const cleanup = () => {
    email = '';
    state = 'inputting';
  };

  $: isValid = isEmail(email);

  const defaultErrorMessage = `
    Sorry, there was an error processing that password reset request.
    If the problem continues, please contact us at support@utheory.com.
    `;

  function resetPassword() {
    api
      .$post('/auth/reset', { email })
      .then(() => {
        state = 'done';
      })
      .catch((err) => {
        state = 'errored';
        errorMessage = err?.message || '';
      });
  }

  const dispatch = createEventDispatcher<{ done: null }>();
</script>

<form
  class={clazz}
  on:submit={(evt) => {
    evt.preventDefault();
  }}
>
  {#if state !== 'done'}
    <p>Enter your email below. We'll send you a password reset link.</p>
    <TextField
      class="mt-6 mb-2"
      type="email"
      bind:value={email}
      label="Email"
      autocomplete="email"
      required
      localValidationError={!isValid}
      customValidityMessage={isValid ? '' : 'Please enter a valid email.'}
    />
    {#if state === 'errored'}
      <div class="text-red my-4">
        {errorMessage || defaultErrorMessage}
      </div>
    {/if}
    <div class="flex justify-end">
      <Button on:click={resetPassword} disabled={!isValid || state === 'requesting'}>
        {#if state !== 'requesting'}
          Request Password Reset Email
        {:else}
          <LoadingSpinner /><span class="sr-only">Requesting...</span>
        {/if}
      </Button>
    </div>
  {:else}
    <H level={2} class="text-gray">Success!</H>
    <p class="my-4 text-gray">
      A password reset email has been sent to {email}. Please follow the link in the email to reset
      your uTheory password.
    </p>

    <div class="flex justify-end">
      <Button on:click={() => dispatch('done')}>Done</Button>
    </div>
  {/if}
</form>
