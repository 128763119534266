<script lang="ts">
  // your script goes here

  import PasswordResetForm from '$lib/app/authentication/PasswordResetForm.svelte';
  import Modal from '$lib/components/Modal.svelte';
  import Logo from '$lib/components/Logo.svelte';
  import { closeModal } from '$lib/stores/modals.store';

  const modalId = 'passwordReset';
</script>

<Modal {modalId} class="max-w-2xl">
  <div
    slot="title"
    class="card-padding text-gray-300 high-contrast:text-gray font-thin text-lg pb-3 border-b border-gray-100"
  >
    <Logo class="h-12 text-cyan-600 mx-auto" />

    Reset your uTheory Password
  </div>

  <div class="card-padding">
    <PasswordResetForm on:done={closeModal} />
  </div>
</Modal>
